<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-xl-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <div style='line-height: 70px;  margin-top: -18px; background: #f4f4f500; margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; margin-bottom: 8px;'>
        <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="display: inline;">
          <!--
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary': state.paneBord==1,  'btn-custom-white': state.paneBord!=1 }"
              @click="state.paneBord=1"
              style='border: 1px solid'
            >
              Vos bordereaux en cours
            </a>
          </li>
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary': state.paneBord==2,  'btn-custom-white': state.paneBord!=2 }"
              @click="state.paneBord=2"
              style='border: 1px solid'
            >
              Historique des bordereaux
            </a>
          </li>
          -->
          <li class="nav-item" style="float: right;">
            <el-tooltip content="Créer un nouveau bordereau" placement="top-end">
              <a
                class="btn btn-custom-red text-active-light me-6 ml-6 h-45px"
                style='margin-left: 10px'
                @click="newBordereau"
              >
                <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;"><inline-svg src="media/icons/duotune/arrows/arr017.svg"/> </span>
                Nouveau bordereau
              </a>
            </el-tooltip>
          </li>
          <li class="nav-item" style="float: right;">
            
            <el-dropdown>
              <el-tooltip content="Exporter la liste des bordereaux" placement="top-end">
                <a class="btn btn-secondary text-active-light me-6 btn-custom-white h-45px">
                  <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;"><inline-svg src="media/icons/duotune/arrows/arr044.svg"/> </span>
                </a>
              </el-tooltip>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="csvExport">Export CSV</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

          </li>

            <li class="nav-item" style="float: right;" v-if="state.mFilter == 'Supprimé'">
              <el-popconfirm title="Voulez vous vraiment vider la corbeille ?" @confirm="deleteCorbeille" confirm-button-text="Oui" cancel-button-text="Non">
                <template #reference>      
                  <button style="border: none; padding: 0; background-color: transparent !important;">
                    <el-tooltip content="Vider la corbeille" placement="top-end">
                      <div class="btn btn-secondary btn-custom-white text-active-light me-6 ml-6 h-45px">
                        <span v-if="!state.loadingVidage" >Vider la corbeille</span>
                        <span v-if="state.loadingVidage" >Suppression...</span>
                        <span v-if="state.loadingVidage" class="spinner-border text-primary spinner-border-sm align-middle ms-2"></span>
                      </div>
                    </el-tooltip>
                  </button>
                  </template>            
                </el-popconfirm>
            </li>
        </ul>
      </div>

      <Overview :key="state.count" title="Liste des bordereaux" type="current"  :exclure="[]" />
      <Overview :key="state.count" title="Historique des bordereaux" type="histo" class="mt-10" :exclure="['Supprimé']" />
    </div>
  </div>


 <el-dialog v-model="state.drawer_bord" title="Création d'un bordereau" width="800">
   <el-row :gutter="10" style="width: 100%">
        <el-col :span="16">

          <div class="row row-user">
              <div class="col"> 
                <el-radio-group v-model="form.type" size="large" class="mb-3" style='width: 100%; text-align: center;'>
                  <el-radio-button label="Conservation intégrale" />
                  <el-radio-button label="Conservation partielle" />
                  <el-radio-button label="Elimination" />
                </el-radio-group>
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Producteur<br>Fournisseur</strong></span></div>
              <div class="col"> 
                <el-select filterable  v-model="form.producteur" class="m-2 new-bord" style='width: 100%; margin-top: 10px;' placeholder="Selectionner une personne morale" size="large" >
                  <el-option v-for="item in state.lProducteurs" :key="item.act_n_seq" :label="`${'' + item.act_c_raison_sociale }`" :value="item.act_n_seq" >
                    <div style='border-top: 1px solid #e3e3e3'>
                      <div>{{ item.act_c_raison_sociale }}</div>
                      <div  class="text-gray-500 fs-7" style="line-height: 10px; margin-bottom: 5px;" v-if="item.act_ark_c_nom2">
                        {{ item.act_ark_c_nom2 }}
                      </div>
                      <div  class="text-gray-500 fs-7" style="line-height: 10px; margin-bottom: 5px;" v-if="item.act_ark_c_nom3">
                        {{ item.act_ark_c_nom3 }}
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>DUA</strong></span></div>
              <div class="col"> 
                <el-input v-model="form.dua" placeholder="Spécifier le DUA" class="m-2">
                  <template #append>années</template>
                </el-input>
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Mot-clés</strong></span></div>
              <div class="col"> 
                <div style='border: 1px solid #dcdfe6;   padding: 3px;  margin: 8px; margin-right: 0px;'>
                  <el-tag v-for="tag in form.tags" :key="tag" class="mx-1" closable :disable-transitions="false" @close="handleClose(tag)">
                    {{ tag }}
                  </el-tag>
                  <el-input v-if="inputVisible" ref="InputRef" v-model="inputValue" class="ml-1" style="width:150px;" size="small" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm"/>
                  <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput">+ Nouveau Mot-clé</el-button>
                </div>
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>Références<br>règlementaire</strong></span></div>
              <div class="col"> 
                <el-input v-model="form.refreglem" type="textarea" :rows="3"  class="m-2" placeholder="Facultatif..."/>
              </div>
          </div>
        </el-col>

        <el-col :span="8" style='padding-right: 18px;' class="label-modal">
          <div v-if="state.parametrage.par_n_identifieurs >= 1">
            <div class="p-5"><span style="font-size: 15px;"><strong>{{ state.parametrage.wof_c_identifieur_1 }}</strong></span></div>
            <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.redacteur" :disabled="!state.myListRole.isADM" >
              <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}}</option>
            </select>
          </div>

          <div v-if="state.parametrage.par_n_identifieurs >= 2">
            <div class="p-5"><span style="font-size: 15px;"><strong>{{ state.parametrage.wof_c_identifieur_2 }}</strong></span></div>
            <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.archiviste">
              <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}}</option>
            </select>
          </div>


          <div v-if="state.parametrage.par_n_identifieurs >= 3">
            <div class="p-5"><span style="font-size: 15px;"><strong>{{ state.parametrage.wof_c_identifieur_3 }}</strong></span></div>
            <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.chefserv">
              <option value="0"><i>Aucun Chef de service</i></option>
              <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}}</option>
            </select>
          </div>


          <div v-if="state.parametrage.par_n_identifieurs >= 4">
            <div class="p-5"><span style="font-size: 15px;"><strong>{{ state.parametrage.wof_c_identifieur_4 }}</strong></span></div>
            <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.direction">
              <option value="0">Aucune direction des archives</option>
              <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}}</option>
            </select>
          </div>


        </el-col>
   </el-row>



    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn btn-secondary" @click="state.drawer_bord = false">Annuler</el-button>
        <el-button class="btn btn-primary" @click="addNewBordereau">Créer le bordereau</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, nextTick, ref } from "vue";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

import { ElInput } from 'element-plus'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import mAxiosApi from "@/api";
import Overview from "@/views/still/deploy/overview.vue"
import { useStore } from "vuex";
import { ElNotification } from 'element-plus';

import * as moment from "moment";

export default defineComponent({
  name: "customer-details",
  components: {
    Overview,
  },
 
  setup() {
    moment.default.locale("fr");

    const state = reactive({
      loaderEnabled: true,
      lProducteurs: [] as any,
      lUsers: [] as any,
      drawer: false,
      drawer_bord : false,
      count: 0,
      paneBord : 1,
      myListRole: [] as any,
      mFilter: '',
      parametrage: {} as any,
      loadingVidage: false,
    });

    const form = reactive({
      type: '',
      producteur : '',
      dua : 30,
      tags : [] as any,
      refreglem : '',
      redacteur: 0,
      archiviste: 0,
      chefserv: 0,
      direction: 0,
    })

    const store = useStore();
    const router = useRouter();
    
    onMounted(async () => {
      MenuComponent.reinitialization();
      
      let mFilter = localStorage.filterBordereau;
      if (mFilter) {
        mFilter = mFilter.replace('/board/overview/', '');
        setCurrentPageBreadcrumbs("- Filtrés par statut "+ mFilter, []);
      } else {
        setCurrentPageBreadcrumbs("", []);
      }
      
      state.mFilter = mFilter;
      state.count = state.count + 1;
      
      if(window.localStorage.getItem('codeArk')){
        const parametrage = await getAxios("/getParametrage")
        store.dispatch(Actions.SET_PARAMETRAGE_ACTION, parametrage.results);
      }
      state.parametrage = store.getters.getParams;
    });

    const newBordereau = async () => {

      form.type = "Conservation intégrale";     
      const myListLiv = await getAxios("/getSVProducteur");
      state.lProducteurs = myListLiv.results;
      form.producteur = "";
      form.dua = 30;
      form.refreglem = '';
      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;
     
      form.redacteur = store.getters.currentUser.uti_n_seq;
      
      let myListRole = {} as any;
      myListRole = await getAxios("/getMyRole");
      state.myListRole = myListRole;

      state.drawer_bord = true;

    }

    const addNewBordereau = async () => {

      if (form.producteur == "") {  
        ElNotification({ title: 'Erreur', message: 'Le champ Fournisseur/ Producteur est obligatoire', type: 'error', })
        return false;
      }

      if (form.archiviste == 0 && state.parametrage.par_n_identifieurs>=2) {  
        ElNotification({ title: 'Erreur', message: 'Le champ '+ state.parametrage.wof_c_identifieur_2 +' est obligatoire', type: 'error', })
        return false;
      }
    
      mAxiosApi
        .post("/addBordereau", form)
        .then((d: any) => {
          ElNotification({ title: 'Succès', message: 'Bordereau créé avec succès !', type: 'success', })
          state.count = state.count + 1;
          state.drawer_bord = false;

          // Affiche le bordereau
          affDetail(d.data);
          store.dispatch(Actions.SET_INCREMENT_REFRESH_BORDEREAU);
        })
    }

    const inputValue = ref('')
    const inputVisible = ref(false)
    const InputRef = ref<InstanceType<typeof ElInput>>()

    const handleClose = (tag: string) => {
      form.tags.splice(form.tags.indexOf(tag), 1)
    }

    const showInput = () => {
      inputVisible.value = true
      nextTick(() => {
        if (InputRef.value) InputRef.value.input.focus()
      })
    }

    const handleInputConfirm = () => {
      if (inputValue.value) {
        form.tags.push(inputValue.value)
      }
      inputVisible.value = false
      inputValue.value = ''
    }


    const csvExport = async () => {

      const myListLiv = await getAxios("/getSVExport");

      const mArray = [] as any;
      
      myListLiv.results.forEach(element => {
        let myElem = JSON.stringify(element);
        
        myElem = myElem.replaceAll('\\n', " ");
        myElem = myElem.replaceAll('\\r', " ");
        myElem = myElem.replaceAll(";", '.');        
        mArray.push(JSON.parse(myElem));
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(mArray[0]).join(";"),
        ...mArray.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      
      const filename = "SV_ExportBordereaux_" + moment.default().format("DD.MM.YYYY_H:mm:ss") + ".csv";

      link.setAttribute("download", filename);
      link.click();
    };

    const affDetail = (d) =>{
        localStorage.setItem("currentBord", d.bor_n_seq);

        const currentBordType = d.bor_c_sort_versement == 'Conservation intégrale' || d.bor_c_sort_versement == 'Conservation partielle' ? 'versement' : 'élimination'
        localStorage.setItem("currentBordType", currentBordType);

        store.dispatch(Actions.SET_CURRENT_BORD, {
          bordSeq: d.bor_n_seq,
          bordType : currentBordType,
        });

        router.push({ name: "bord" });

    }

    const deleteCorbeille = async() => {
      if(state.loadingVidage) return;
      state.loadingVidage = true;
      const results = await mAxiosApi.get("/deleteCorbeille");
      window.localStorage.setItem('filterBordereau', "");
      state.count = state.count+1;
      state.mFilter = '';
      store.dispatch(Actions.SET_INCREMENT_REFRESH_BORDEREAU);
      router.push({name: 'board'})
      state.loadingVidage = false;
    }

    return {
      state,
      form,
      handleClose,
      showInput,
      handleInputConfirm,
      inputValue,
      inputVisible,
      InputRef,
      newBordereau,
      addNewBordereau,
      csvExport,
      affDetail,
      deleteCorbeille
    }
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
<style>
.el-select-dropdown__item.new-bord {
    min-height: 48px;
    line-height: 24px;
}
</style>